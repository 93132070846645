// @flow

import * as React from 'react'
import Analytics from '../../utils/analytics'

type Props = {
  href: string,
  analytics: string,
  children: React.Node,
  onClick?: Function,
}

class OutboundLink extends React.Component<Props, *> {
  static defaultProps = {
    onClick: () => {},
  }

  handleClick = () => {
    const { onClick, analytics } = this.props

    if (analytics) {
      Analytics.event({
        category: 'Outbound Navigation',
        action: 'Click',
        label: analytics,
      })
    }

    if (onClick) {
      onClick()
    }
  }

  render() {
    const { href, children, ...rest } = this.props

    return (
      <a href={href} {...rest} onClick={this.handleClick}>
        {children}
      </a>
    )
  }
}

export default OutboundLink
