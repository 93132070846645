// @flow

import React from 'react'
import memoize from 'memoize-one'
import Analytics from '../../utils/analytics'
import Icon from '../Icon'
import GojiLogo from './assets/goji-logo.png'
import {
  Container,
  LogoLink,
  Logo,
  MenuButton,
  OverflowIcon,
  NavItemsContainer,
  NavLink,
  activeLinkStyle,
  MobileNavContainer,
  MobileNavItems,
  CloseIcon,
} from './style'

type Props = {
  location: any,
}

type State = {
  mobileNavIsOpen: boolean,
}

class Nav extends React.Component<Props, State> {
  state = {
    mobileNavIsOpen: false,
  }

  navItems = memoize((pathname: string) => {
    const defaultNavItems = [
      {
        href: '/features',
        label: 'Features',
      },
      // {
      //   href: '/how-it-works',
      //   label: 'How it Works',
      // },
      {
        href: '/about-us',
        label: 'About Us',
      },
      {
        href: '/get-in-touch',
        label: 'Get in Touch',
      },
    ]

    if (pathname !== '/') {
      return [
        {
          href: '/',
          label: 'Home',
        },
        ...defaultNavItems,
      ]
    }

    return defaultNavItems
  })

  toggleMobileNav = () => {
    const { mobileNavIsOpen } = this.state

    Analytics.event({
      category: 'Navigation',
      action: 'Toggled Menu',
      label: mobileNavIsOpen ? 'close' : 'open',
    })

    this.setState({ mobileNavIsOpen: !mobileNavIsOpen })
  }

  renderMobileNav = () => {
    const { location } = this.props
    const { mobileNavIsOpen } = this.state
    const navItems = this.navItems(location.pathname)

    if (!mobileNavIsOpen) {
      return null
    }

    return (
      <MobileNavContainer>
        <LogoLink to="/">
          <span>Goji Geotainment - Home</span>
          <Logo src={GojiLogo} alt="Goji Geotainment logo" />
        </LogoLink>
        <CloseIcon onClick={this.toggleMobileNav}>
          <Icon type="close" />
        </CloseIcon>
        <MobileNavItems>
          {navItems.map(item => (
            <NavLink
              key={item.href}
              to={item.href}
              activeStyle={activeLinkStyle}
              analytics={`Main Navigation: ${item.label}`}
            >
              {item.label}
            </NavLink>
          ))}
        </MobileNavItems>
      </MobileNavContainer>
    )
  }

  render() {
    const { location } = this.props
    const navItems = this.navItems(location.pathname)

    return (
      <Container>
        {this.renderMobileNav()}
        <LogoLink to="/">
          <span>Goji Geotainment - Home</span>
          <Logo src={GojiLogo} alt="Goji Geotainment logo" />
        </LogoLink>
        <div>
          <MenuButton onClick={this.toggleMobileNav}>
            <OverflowIcon>
              <Icon type="overflow" />
            </OverflowIcon>
            Menu
          </MenuButton>
          <NavItemsContainer>
            {navItems.map(item => (
              <NavLink
                key={item.href}
                to={item.href}
                activeStyle={activeLinkStyle}
                analytics={`Main Navigation: ${item.label}`}
              >
                {item.label}
              </NavLink>
            ))}
          </NavItemsContainer>
        </div>
      </Container>
    )
  }
}

export default Nav
