import styled from '@emotion/styled'
import { css } from '@emotion/core'
import OutboundLink from '../OutboundLink'
import Colors from '../../styles/colors'
import * as Fonts from '../../styles/fonts'
import { media } from '../../styles/dimensions'

export const globalStyles = css`
  html {
    height: 100%;
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    height: 100%;
    position: relative;
  }

  button {
    outline: none;
  }

  #iubenda-cs-banner {
    bottom: 0 !important;
    left: 0 !important;
    position: fixed !important;
    width: 100% !important;
    z-index: 99999998 !important;
    background-color: ${Colors.pureBlack};
  }

  .iubenda-cs-content {
    display: block;
    margin: 0 auto;
    padding: 20px;
    width: auto;
    font-family: Helvetica, Arial, FreeSans, sans-serif;
    font-size: 14px;
    background: ${Colors.pureBlack};
    color: ${Colors.white};
  }

  .iubenda-cs-rationale {
    max-width: 900px;
    position: relative;
    margin: 0 auto;
  }

  .iubenda-banner-content > p {
    font-family: Helvetica, Arial, FreeSans, sans-serif;
    line-height: 1.5;
  }

  .iubenda-cs-close-btn {
    margin: 0;
    color: ${Colors.white};
    text-decoration: none;
    font-size: 14px;
    position: absolute;
    top: 0;
    right: 0;
    border: none;
  }

  .iubenda-cs-cookie-policy-lnk {
    text-decoration: underline;
    color: ${Colors.white};
    font-size: 14px;
    font-weight: 900;
  }

  body.modal-open {
    overflow: hidden;
  }
`

export const Footer = styled.footer`
  background: ${Colors.grey4};
  padding: 24px;
  text-align: center;

  ${media.desktop`
    padding: 24px 72px;
    display: grid;
    grid-template-columns: 2fr 3fr 3fr 2fr;
    grid-template-areas:
      "logo copyright copyright social"
      "logo contact address ."
      "logo privacy privacy .";
    grid-column-gap: 16px;
    grid-row-gap: 8px;
  `};
`

export const LogoContainer = styled.div`
  margin-bottom: 16px;

  ${media.desktop`
    grid-area: logo;
    place-self: start left;
    margin-bottom: 0;
  `};
`

const DefaultTextStyle = css`
  ${Fonts.Caption};
  color: ${Colors.white};
  margin-bottom: 16px;

  a {
    ${Fonts.Link};
    color: ${Colors.white};
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  ${media.desktop`
    margin-bottom: 0;
  `};
`

export const Copyright = styled.p`
  ${DefaultTextStyle};

  ${media.desktop`
    grid-area: copyright;
    align-self: start;
    justify-self: center;
    text-align: center;
  `};
`

export const EmailPhone = styled.p`
  ${DefaultTextStyle};
  ${media.desktop`
    grid-area: contact;
    place-self: start end;
    text-align: right;
  `};
`

export const Address = styled.p`
  ${DefaultTextStyle};

  ${media.desktop`
    grid-area: address;
    place-self: start start;
    text-align: left;
  `};
`

export const Privacy = styled.p`
  ${DefaultTextStyle};
  ${media.desktop`
    grid-area: privacy;
    place-self: start center;
    text-align: center;
  `};
`

export const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;

  ${media.desktop`
    grid-area: social;
    align-self: start;
    justify-self: end;
    margin-bottom: 0;
  `};
`

SocialIcons.Icon = styled(OutboundLink)`
  display: block;
  width: 24px;
  height: 24px;
  color: ${Colors.white};
  margin-right: 16px;
  overflow: hidden;
  position: relative;

  &:last-of-type {
    margin-right: 0;
  }

  span {
    position: absolute;
    top: -200px;
  }
`
