// @flow

import * as React from 'react'
import Obfuscate from 'react-obfuscate'
import { Global } from '@emotion/core'
import GDPR from '../../utils/gdpr'
import Analytics from '../../utils/analytics'
import OutboundLink from '../OutboundLink'
import Logo from '../Logo'
import Icon from '../Icon'
import {
  globalStyles,
  Footer,
  LogoContainer,
  Copyright,
  EmailPhone,
  Address,
  Privacy,
  SocialIcons,
} from './style'

type Dependencies = 'slick'
type Props = {
  children: React.Node,
  dependencies?: Array<Dependencies>,
  showFooter?: boolean,
}

class Layout extends React.Component<Props, *> {
  static defaultProps = {
    dependencies: [],
    showFooter: true,
  }

  componentDidMount() {
    GDPR.initialize()
  }

  renderDependencies = () => {
    const { dependencies = [] } = this.props

    return [
      ...dependencies.map(dep => {
        switch (dep) {
          case 'slick':
            return (
              <React.Fragment key="slick">
                <link
                  rel="stylesheet"
                  type="text/css"
                  charSet="UTF-8"
                  href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
                />
                <link
                  rel="stylesheet"
                  type="text/css"
                  href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
                />
              </React.Fragment>
            )
          default:
            return null
        }
      }),
      <link
        key="google-fonts"
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400"
      />,
    ]
  }

  render() {
    const { children, showFooter } = this.props

    return (
      <>
        <Global styles={globalStyles} />
        <div>
          {children}
          {showFooter && (
            <Footer>
              <LogoContainer>
                <Logo />
              </LogoContainer>
              <Copyright>
                © {new Date().getFullYear()} Gotham Studios, Inc. GojiBox® is a registered trademark
                of Gotham Studios, Inc.
              </Copyright>
              <EmailPhone>
                <Obfuscate
                  email="info@gothamstudios.com"
                  onClick={() => {
                    Analytics.event({
                      category: 'Outbound Navigation',
                      action: 'Click',
                      label: 'Footer: Email Address',
                    })
                  }}
                />
                <br />
                <Obfuscate
                  tel="+1 (202) 505-5001"
                  onClick={() => {
                    Analytics.event({
                      category: 'Outbound Navigation',
                      action: 'Click',
                      label: 'Footer: Telephone',
                    })
                  }}
                />
              </EmailPhone>
              <Address>
                1655 Fort Myer Drive, Suite 700
                <br />
                Arlington, VA 22209
              </Address>
              <SocialIcons>
                <SocialIcons.Icon
                  href="https://www.facebook.com/GojiGeotainment"
                  target="_blank"
                  rel="noopener"
                  analytics="Footer: https://www.facebook.com/GojiGeotainment"
                >
                  <span>Goji Facebook</span>
                  <Icon type="facebook" />
                </SocialIcons.Icon>
                <SocialIcons.Icon
                  href="https://twitter.com/GojiGeotainment"
                  target="_blank"
                  rel="noopener"
                  analytics="Footer: https://twitter.com/GojiGeotainment"
                >
                  <span>Goji Twitter</span>
                  <Icon type="twitter" />
                </SocialIcons.Icon>
                <SocialIcons.Icon
                  href="https://www.instagram.com/gojigeotainment/"
                  target="_blank"
                  rel="noopener"
                  analytics="Footer: https://www.instagram.com/gojigeotainment/"
                >
                  <span>Goji Instagram</span>
                  <Icon type="instagram" />
                </SocialIcons.Icon>
              </SocialIcons>
              <Privacy>
                <OutboundLink
                  href="https://www.iubenda.com/privacy-policy/39120124"
                  target="_blank"
                  rel="noopener noreferrer"
                  analytics="Footer: https://www.iubenda.com/privacy-policy/39120124"
                >
                  Privacy Policy
                </OutboundLink>
                &nbsp;&nbsp;•&nbsp;&nbsp;
                <OutboundLink
                  href="https://www.iubenda.com/privacy-policy/39120124/cookie-policy"
                  target="_blank"
                  rel="noopener noreferrer"
                  analytics="Footer: https://www.iubenda.com/privacy-policy/39120124/cookie-policy"
                >
                  Cookie Policy
                </OutboundLink>
              </Privacy>
            </Footer>
          )}
        </div>
        {this.renderDependencies()}
      </>
    )
  }
}

export default Layout
