// @flow
import ReactGA from 'react-ga'
import GDPR from './gdpr'

class Analytics {
  initialized = false

  initialize = () =>
    new Promise<void>((resolve, reject) => {
      if (this.initialized) {
        return resolve()
      }

      GDPR.initialize().then(() => {
        const { GATSBY_GOOGLE_ANALYTICS_ID } = process.env

        if (GATSBY_GOOGLE_ANALYTICS_ID) {
          ReactGA.initialize(GATSBY_GOOGLE_ANALYTICS_ID)
          this.initialized = true
          return resolve()
        }

        return reject()
      })

      return null
    })

  pageview = () =>
    this.initialize().then(() => {
      // Allow Helmet to update title
      setTimeout(() => {
        ReactGA.pageview(window.location.pathname + window.location.search + window.location.hash)
      }, 500)
    })

  event = (data: { category: string, action: string, value?: number, label?: string }) => {
    this.initialize().then(() => {
      ReactGA.event(data)
    })
  }
}

export default new Analytics()
