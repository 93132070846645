import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { media } from '../../styles/dimensions'

const Container = styled.div`
  padding: 0 24px;
  margin: 0 auto;
  max-width: 1152px;

  ${media.desktop`
    padding: 0 144px;
  `};
`

const PaddedContent = ({ children }) => <Container>{children}</Container>

PaddedContent.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PaddedContent
