// @flow

import * as React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import Analytics from '../../utils/analytics'

type Props = {
  to: string,
  activeStyle?: *,
  analytics: string,
  children: React.Node,
  onClick?: Function,
}

class Link extends React.Component<Props, *> {
  static defaultProps = {
    activeStyle: null,
    onClick: () => {},
  }

  handleClick = () => {
    const { onClick, analytics } = this.props

    if (analytics) {
      Analytics.event({
        category: 'Navigation',
        action: 'Click',
        label: analytics,
      })
    }

    if (onClick) {
      onClick()
    }
  }

  render() {
    const { to, activeStyle, children, ...rest } = this.props

    return (
      <GatsbyLink to={to} activeStyle={activeStyle} {...rest} onClick={this.handleClick}>
        {children}
      </GatsbyLink>
    )
  }
}

export default Link
