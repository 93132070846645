import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Link from '../Link'
import { media } from '../../styles/dimensions'
import Colors from '../../styles/colors'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 48px;
  position: relative;

  ${media.tablet`
    padding: 48px 72px 104px;
  `};
`

export const LogoLink = styled(Link)`
  position: relative;
  overflow: hidden;

  span {
    position: absolute;
    top: -300px;
  }
`

export const Logo = styled.img`
  height: 32px;
  user-select: none;

  ${media.tablet`
    width: 72px;
    height: auto;
  `};
`

export const MenuButton = styled.button`
  border: 0;
  background: none;
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0;
  text-align: right;
  color: ${Colors.white};
  display: flex;
  align-items: center;

  ${media.tablet`
    display: none;
  `};
`

export const OverflowIcon = styled.span`
  width: 24px;
  height: 24px;
`

export const NavItemsContainer = styled.div`
  display: none;

  ${media.tablet`
    display: block;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0;
    text-align: right;
    user-select: none;
  `};
`

export const activeLinkStyle = {
  background: Colors.util.fade({ color: Colors.white, amount: 0.9 }),
}

export const NavLink = styled(Link)`
  text-decoration: none;
  color: ${Colors.white};
  display: block;
  border-radius: 6px;
  padding: 8px 8px;
  margin-bottom: 8px;
  transition: background 1s;

  &:hover {
    ${css`
      ${activeLinkStyle}
    `};
    transition: none;
  }

  ${media.tablet`
    display: inline-block;
    margin: 0 0 0 16px;
  `};
`

export const MobileNavContainer = styled.div`
  position: absolute;
  background: ${Colors.backgroundGradient};
  border-bottom: 1px solid ${Colors.util.fade({ color: Colors.white, amount: 0.5 })};
  top: 0;
  right: 0;
  left: 0;
  padding: 24px;
  z-index: 10;
  box-shadow: 0 24px 32px 0 rgba(5, 29, 47, 0.5);

  ${media.tablet`
    display: none;
  `};
`

export const MobileNavItems = styled.div`
  user-select: none;
  font-weight: 500;
  margin: 16px -8px 0 -8px;

  a {
    &:last-child {
      margin-bottom: 8px;
    }
  }
`

export const CloseIcon = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 22px;
  right: 16px;
  color: ${Colors.white};
`
