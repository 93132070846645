// @flow
class GDPR {
  initialized = false

  isInitialized = () => this.initialized

  initialize = () =>
    new Promise<void>(resolve => {
      if (this.initialized) {
        return resolve()
      }

      /* eslint-disable no-underscore-dangle */
      window._iub = window._iub || []
      window._iub.csConfiguration = {
        lang: 'en',
        siteId: 1371677,
        banner: { slideDown: false, applyStyles: false },
        cookiePolicyId: 39120124,
        callback: {
          onConsentRead: () => {
            this.initialized = true

            return resolve()
          },
        },
      }

      const script = document.createElement('script')
      script.src = '//cdn.iubenda.com/cookie_solution/safemode/iubenda_cs.js'

      if (document.body) {
        document.body.appendChild(script)
      }

      return null
    })
}

const gdpr = new GDPR()

export default gdpr
