// @flow

import * as React from 'react'

type Props = {
  width?: string,
  height?: string,
}

const Logo = ({ width, height }: Props) => (
  <svg width={width} height={height} viewBox="0 0 78 42">
    <path
      fill="rgb(254, 254, 254)"
      d="M 20.33,4.53 C 19.05,3.81 17.49,3.44 15.65,3.44 14.42,3.44 13.2,3.7 11.99,4.21 10.77,4.73 9.69,5.49 8.75,6.52 7.8,7.54 7.03,8.83 6.44,10.36 5.86,11.9 5.56,13.71 5.56,15.79 5.56,17.36 5.78,18.82 6.22,20.16 6.66,21.5 7.3,22.67 8.12,23.65 8.95,24.64 9.97,25.41 11.17,25.96 12.37,26.51 13.74,26.79 15.29,26.79 15.63,26.79 16.03,26.77 16.47,26.73 16.91,26.69 17.34,26.64 17.75,26.59 18.16,26.54 18.54,26.46 18.89,26.37 19.24,26.28 19.49,26.18 19.65,26.08 19.7,25.76 19.74,25.37 19.75,24.89 19.76,24.42 19.77,23.89 19.77,23.32 19.77,21.19 19.73,19.62 19.65,18.6 19.57,17.59 19.52,16.9 19.49,16.53 19.76,16.59 20.11,16.63 20.55,16.67 20.99,16.71 21.62,16.73 22.45,16.73 23.41,16.73 24.13,16.71 24.59,16.67 25.06,16.63 25.43,16.59 25.69,16.53 25.64,16.75 25.59,17.09 25.55,17.56 25.51,18.03 25.48,18.58 25.45,19.2 25.43,19.81 25.41,20.47 25.39,21.17 25.38,21.86 25.37,22.53 25.37,23.16 L 25.37,24.46 C 25.37,25.12 25.38,25.73 25.39,26.29 25.41,26.86 25.44,27.26 25.49,27.5 23.73,28 21.95,28.39 20.15,28.68 18.35,28.97 16.57,29.11 14.81,29.11 12.51,29.11 10.4,28.82 8.46,28.22 6.53,27.63 4.86,26.77 3.46,25.62 2.06,24.48 0.97,23.1 0.18,21.48 -0.61,19.87 -1,18.05 -1,16.02 -1,13.52 -0.58,11.34 0.26,9.46 1.1,7.58 2.26,6.01 3.74,4.76 5.22,3.52 6.98,2.58 9.01,1.95 11.03,1.32 13.22,1 15.57,1 17.6,1 19.35,1.2 20.83,1.61 22.31,2.02 23.77,2.6 25.21,3.37 25,4.05 24.79,4.72 24.59,5.38 24.39,6.03 24.23,6.7 24.09,7.39 L 23.53,7.39 C 22.68,6.2 21.61,5.25 20.33,4.53 Z M 20.33,4.53"
    />
    <path
      fill="rgb(254, 254, 254)"
      d="M 55.39,32.38 C 55.76,32.15 56.07,31.87 56.31,31.56 56.55,31.24 56.73,30.85 56.85,30.37 56.97,29.9 57.06,29.31 57.11,28.6 57.22,26.97 57.3,25.31 57.35,23.61 57.4,21.92 57.43,19.98 57.43,17.8 L 57.43,12.43 C 57.43,10.25 57.4,8.31 57.35,6.62 57.3,4.92 57.22,3.22 57.11,1.51 57.56,1.57 58.06,1.61 58.59,1.65 59.12,1.69 59.72,1.71 60.39,1.71 61.06,1.71 61.66,1.69 62.19,1.65 62.73,1.61 63.22,1.57 63.67,1.51 63.57,3.22 63.49,4.92 63.43,6.62 63.38,8.31 63.35,10.25 63.35,12.43 L 63.35,17.8 C 63.35,18.9 63.37,20.06 63.39,21.27 63.42,22.48 63.43,23.71 63.43,24.97 63.43,28.05 62.69,30.36 61.21,31.91 59.73,33.46 57.52,34.47 54.59,34.95 L 53.99,33.09 C 54.55,32.86 55.02,32.62 55.39,32.38 Z M 55.39,32.38"
    />
    <path
      fill="rgb(254, 254, 254)"
      d="M 70.68,6.62 C 70.62,4.92 70.54,3.22 70.44,1.51 70.89,1.57 71.38,1.61 71.92,1.65 72.45,1.69 73.05,1.71 73.72,1.71 74.38,1.71 74.99,1.69 75.52,1.65 76.05,1.61 76.55,1.57 77,1.51 76.89,3.22 76.81,4.92 76.76,6.62 76.71,8.31 76.68,10.25 76.68,12.43 L 76.68,17.8 C 76.68,19.98 76.71,21.92 76.76,23.61 76.81,25.31 76.89,26.97 77,28.6 76.55,28.55 76.05,28.52 75.52,28.52 L 73.72,28.52 71.92,28.52 C 71.38,28.52 70.89,28.55 70.44,28.6 70.54,26.97 70.62,25.31 70.68,23.61 70.73,21.92 70.76,19.98 70.76,17.8 L 70.76,12.43 C 70.76,10.25 70.73,8.31 70.68,6.62 Z M 70.68,6.62"
    />
    <path
      fill="rgb(248, 155, 28)"
      d="M 41.15,21.41 C 36.27,21.41 32.32,17.52 32.32,12.71 32.32,7.91 36.27,4.01 41.15,4.01 46.03,4.01 49.98,7.91 49.98,12.71 49.98,17.52 46.03,21.41 41.15,21.41 Z M 52.72,12.71 C 52.72,6.41 47.54,1.31 41.15,1.31 34.76,1.31 29.58,6.41 29.58,12.71 29.58,17.76 32.91,22.04 37.53,23.54 L 37.53,23.54 41.02,34.59 44.38,23.66 44.38,23.66 C 49.2,22.28 52.72,17.9 52.72,12.71 Z M 52.72,12.71"
    />
    <path
      fill="rgb(254, 254, 254)"
      d="M 4.22,39.49 L 5.82,39.49 5.82,40.4 C 5.81,40.84 5.66,41.22 5.36,41.52 5.05,41.82 4.67,41.97 4.21,41.98 3.85,41.97 3.56,41.88 3.32,41.72 3.08,41.57 2.9,41.38 2.79,41.18 2.76,41.11 2.73,41.05 2.7,40.99 2.68,40.92 2.66,40.84 2.64,40.75 2.61,40.57 2.59,40.23 2.59,39.72 2.59,39.2 2.61,38.85 2.64,38.68 2.67,38.5 2.72,38.36 2.79,38.25 2.9,38.05 3.08,37.87 3.32,37.7 3.56,37.54 3.85,37.46 4.21,37.45 4.64,37.46 5,37.59 5.27,37.84 5.55,38.09 5.72,38.4 5.8,38.77 L 5.07,38.77 C 5.01,38.59 4.91,38.44 4.77,38.32 4.61,38.2 4.43,38.13 4.21,38.13 4.05,38.13 3.91,38.16 3.79,38.22 3.68,38.28 3.58,38.36 3.51,38.45 3.42,38.55 3.36,38.67 3.33,38.82 3.3,38.98 3.28,39.28 3.28,39.72 3.28,40.15 3.3,40.45 3.33,40.6 3.36,40.76 3.42,40.88 3.51,40.98 3.58,41.07 3.68,41.15 3.79,41.2 3.91,41.27 4.05,41.3 4.21,41.3 4.48,41.3 4.7,41.21 4.87,41.03 5.04,40.86 5.13,40.64 5.14,40.36 L 5.14,40.13 4.22,40.13 4.22,39.49 Z M 4.22,39.49"
    />
    <path
      fill="rgb(254, 254, 254)"
      d="M 10.11,37.49 L 12.99,37.49 12.99,38.13 10.79,38.13 10.79,39.4 12.67,39.4 12.67,40 10.79,40 10.79,41.3 12.99,41.3 12.99,41.94 10.11,41.94 10.11,37.49 Z M 10.11,37.49"
    />
    <path
      fill="rgb(254, 254, 254)"
      d="M 17.66,39.72 C 17.66,40.15 17.68,40.45 17.71,40.6 17.74,40.76 17.8,40.88 17.89,40.98 17.96,41.07 18.05,41.15 18.17,41.2 18.29,41.27 18.43,41.3 18.59,41.3 18.75,41.3 18.89,41.27 19.01,41.2 19.13,41.15 19.22,41.07 19.28,40.98 19.37,40.88 19.43,40.76 19.47,40.6 19.5,40.45 19.51,40.15 19.51,39.72 19.51,39.28 19.5,38.98 19.47,38.82 19.43,38.67 19.37,38.55 19.28,38.45 19.22,38.36 19.13,38.28 19.01,38.22 18.89,38.16 18.75,38.13 18.59,38.13 18.43,38.13 18.29,38.16 18.17,38.22 18.05,38.28 17.96,38.36 17.89,38.45 17.8,38.55 17.74,38.67 17.71,38.82 17.68,38.98 17.66,39.28 17.66,39.72 Z M 16.97,39.72 C 16.97,39.2 16.99,38.85 17.02,38.68 17.05,38.5 17.1,38.36 17.17,38.25 17.28,38.05 17.46,37.87 17.7,37.7 17.94,37.54 18.23,37.46 18.59,37.45 18.95,37.46 19.25,37.54 19.49,37.7 19.72,37.87 19.9,38.05 20,38.25 20.08,38.36 20.13,38.5 20.16,38.68 20.19,38.85 20.2,39.2 20.2,39.72 20.2,40.23 20.19,40.57 20.16,40.75 20.13,40.93 20.08,41.07 20,41.18 19.9,41.38 19.72,41.57 19.49,41.72 19.25,41.88 18.95,41.97 18.59,41.98 18.23,41.97 17.94,41.88 17.7,41.72 17.46,41.57 17.28,41.38 17.17,41.18 17.14,41.11 17.11,41.05 17.08,40.99 17.06,40.92 17.04,40.84 17.02,40.75 16.99,40.57 16.97,40.23 16.97,39.72 Z M 16.97,39.72"
    />
    <path
      fill="rgb(254, 254, 254)"
      d="M 25.14,38.09 L 23.9,38.09 23.9,37.49 27.06,37.49 27.06,38.09 25.83,38.09 25.83,41.94 25.14,41.94 25.14,38.09 Z M 25.14,38.09"
    />
    <path
      fill="rgb(254, 254, 254)"
      d="M 32.68,40.35 L 32.03,38.4 32.02,38.4 31.36,40.35 32.68,40.35 Z M 32.88,40.95 L 31.17,40.95 30.82,41.94 30.09,41.94 31.74,37.49 32.31,37.49 33.95,41.94 33.23,41.94 32.88,40.95 Z M 32.88,40.95"
    />
    <rect fill="rgb(254, 254, 254)" x="37.72" y="37.2" width="1.35" height="4.65" />
    <path
      fill="rgb(254, 254, 254)"
      d="M 43.05,37.49 L 43.69,37.49 45.74,40.65 45.75,40.65 45.75,37.49 46.44,37.49 46.44,41.94 45.79,41.94 43.75,38.78 43.73,38.78 43.73,41.94 43.05,41.94 43.05,37.49 Z M 43.05,37.49"
    />
    <path
      fill="rgb(254, 254, 254)"
      d="M 50.88,37.49 L 51.52,37.49 52.82,40.39 54.09,37.49 54.74,37.49 54.74,41.94 54.05,41.94 54.05,39.17 54.04,39.17 53.09,41.3 52.52,41.3 51.58,39.17 51.57,39.17 51.57,41.94 50.88,41.94 50.88,37.49 Z M 50.88,37.49"
    />
    <path
      fill="rgb(254, 254, 254)"
      d="M 59.18,37.49 L 62.06,37.49 62.06,38.13 59.86,38.13 59.86,39.4 61.74,39.4 61.74,40 59.86,40 59.86,41.3 62.06,41.3 62.06,41.94 59.18,41.94 59.18,37.49 Z M 59.18,37.49"
    />
    <path
      fill="rgb(254, 254, 254)"
      d="M 66.2,37.49 L 66.84,37.49 68.89,40.65 68.9,40.65 68.9,37.49 69.58,37.49 69.58,41.94 68.94,41.94 66.89,38.78 66.88,38.78 66.88,41.94 66.2,41.94 66.2,37.49 Z M 66.2,37.49"
    />
    <path
      fill="rgb(254, 254, 254)"
      d="M 74.79,38.09 L 73.56,38.09 73.56,37.49 76.72,37.49 76.72,38.09 75.48,38.09 75.48,41.94 74.79,41.94 74.79,38.09 Z M 74.79,38.09"
    />
  </svg>
)

Logo.defaultProps = {
  width: '78px',
  height: '40px',
}

export default Logo
