// @flow

import { css } from '@emotion/core'
import Colors from './colors'

export const Title = css`
  font-size: 52px;
  color: ${Colors.grey1};
  letter-spacing: -2px;
  line-height: 58px;
  font-weight: 300;
  margin: 0;
`

export const Title2 = css`
  font-weight: 300;
  font-size: 32px;
  color: ${Colors.grey1};
  letter-spacing: 0;
  line-height: 38px;
  margin: 0;
`

export const Subtitle = css`
  opacity: 0.8;
  font-weight: 300;
  color: ${Colors.grey2};
  font-size: 22px;
  letter-spacing: 0;
  line-height: 32px;
  margin: 0;
`

export const Subtitle2 = css`
  font-weight: 400;
  font-size: 18px;
  color: ${Colors.grey1};
  letter-spacing: 0;
  margin: 0;
  line-height: 25px;
`

export const Body = css`
  opacity: 0.6;
  font-weight: 400;
  font-size: 16px;
  color: ${Colors.grey2};
  letter-spacing: 0;
  line-height: 25px;
  margin: 0;
  padding: 0;
`

export const Body2 = css`
  font-weight: 400;
  font-size: 16px;
  color: ${Colors.grey4};
  letter-spacing: 0;
  line-height: 25px;
  margin: 0;
`

export const Caption = css`
  font-weight: 400;
  font-size: 14px;
  color: ${Colors.grey4};
  letter-spacing: 0;
  line-height: 20px;
  margin: 0;
`

export const Link = css`
  font-weight: 400;
  color: ${Colors.secondary};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

export const Button = css`
  font-weight: 400;
  font-size: 16px;
  color: ${Colors.white};
  letter-spacing: 0;
  line-height: 16px;
  margin: 0;
  border: 0;
  padding: 0;
  background: transparent;
`
